import React from "react";
import "./Content.css";
import { data } from '../../constants/';
import { RoundedSubHeader } from '../../components/Headings/Headings';
import { addHyperLinks } from '../News/InternalHyperLinks';
import ReactMarkdown from "react-markdown";
import { useLanguage } from '../../constants/localisations/language_context';
const Content = () => {
  const { currentLanguage } = useLanguage();
  return (
    <div className="content">

      {data.ChatGPTCourseSEO.map(({ header, headerTagline, text, link, linkTitle }, index) => (
        <div className="service-container" key={index}>
          <RoundedSubHeader title={header} text={headerTagline} />
          <div className="consult-text">
            <ReactMarkdown>
              {addHyperLinks(text, currentLanguage)}
            </ReactMarkdown>
            {link &&
              <div>
                <br />
                <a href={link}>{linkTitle}</a>
              </div>
            }
          </div>
          <br /><br />
        </div>
      ))}
      <br /><br />

    </div>
  );
};

export default Content;
