import React from 'react'
import './Testimonials.css';
import { RoundedSubHeader } from '../../components/Headings/Headings';
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG } from '../../constants/strings';
import data_eng from '../../constants/data_eng';
import data_sv from '../../constants/data';

const ConsultTestimonials = () => {
  const { currentLanguage } = useLanguage();

  const data = currentLanguage === LANG_ENG ? data_eng.ConsultTestimonials : data_sv.ConsultTestimonials;
  const testimonialsText = currentLanguage === LANG_ENG ? "What our customers say" : "Vad våra kunder säger";
  
  return (
    <div className="section-padding" id="testimonials">
      <RoundedSubHeader title="Testamonials" text={testimonialsText} />
      <br/><br/>
      <div className="testimonials-container">
        {data.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="speech-bubble">{testimonial.testimonial}</p>
            <div className='testimonial-info'>
              <div className='testimonial-image'>
                <img src={testimonial.photo} alt={testimonial.name} />
              </div>
              <div className="testimonial-name">{testimonial.name}{testimonial.position}</div>
            </div>
          </div>  
        ))}
      </div>
      <br/>
    </div>
  )
}

export default ConsultTestimonials;