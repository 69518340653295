import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Menu.css';
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../Firebase/firebase';
import images from '../../constants/images';
import { useLanguage } from '../../constants/localisations/language_context';
import data_eng from '../../constants/data_eng';
import data_sv from '../../constants/data';
import { LANG_ENG, LANG_SV } from '../../constants/strings';

const Menu = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { currentLanguage, changeLanguage } = useLanguage();
    const menuData = currentLanguage === LANG_ENG ? data_eng : data_sv;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        auth.signOut();
        window.location.href = '/chatgpt-kurs';
    };

    const handleLanguage = (language) => {
        changeLanguage(language);
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
            <Container>
                <Navbar.Brand href="/"> 
                <img
        src={images.logo}
        width="100" // Adjust width as needed
        height="100" // Adjust height as needed
        className="d-inline-block align-top" // Keeps the logo aligned properly
        alt="Techlära Logo"
        title="Techlära Logo"
    />
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        {menuData.Menu.map((item, index) => {
                            if (isLoggedIn && item.id === 'login') {
                                return <button key={`menu-${index}`} className="btn-logout" onClick={handleLogout}>Logga ut</button>;
                            }
                            
                            if (item.id === 'language') {
                                return (
                                    <div key={`menu-${index}`} className="btn-language-container">
                                        <button className={`btn-language ${currentLanguage === LANG_ENG ? 'btn-language-selected' : ''}`} onClick={() => handleLanguage(LANG_ENG)}>🇬🇧</button>
                                        <button className={`btn-language ${currentLanguage === LANG_SV ? 'btn-language-selected' : ''}`} onClick={() => handleLanguage(LANG_SV)}>🇸🇪</button>
                                    </div>
                                );
                            }
                            
                            return (
                                <Nav.Link key={`menu-${index}`} href={item.link}>
                                    {item.text}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Menu