import thingshappen  from '../assets/thingshappen.png';
import services01  from '../assets/services01.png';
import services02  from '../assets/services02.png';
import services03  from '../assets/services03.png';
import services04  from '../assets/services04.png';
import services05  from '../assets/services05.png';
import services06  from '../assets/services06.png';
import team01 from '../assets/team01.png';
import i1 from '../assets/i1.svg';
import i2 from '../assets/i2.svg';
import i3 from '../assets/i3.svg';
import i4 from '../assets/i4.svg';
import i5 from '../assets/i5.svg';
import i6 from '../assets/i6.svg';
import hero from '../assets/hero.jpg';
import chatGPTmascot from '../assets/new_openai_robot.jpg';
import carpenter from '../assets/carpenter.jpg';
import robotReading from '../assets/robot_reading.png';
import robotByDesk from '../assets/robot_by_desk.jpg';
import testimonial1 from '../assets/stephen_portrait.png';
import testimonial2 from '../assets/linn_portrait.png';
import testimonial3 from '../assets/erik_svedin.png';
import testimonial4 from '../assets/peter.png';
import hi from '../assets/hi.png';
import logo from '../assets/logo512.jpg';
import aiconsult from '../assets/ai_konsult_stockolm.jpg';
import aiface from '../assets/ai_face.jpg';
import brandlab from '../assets/brand_lab.png';
import artaiLogo from '../assets/artai_logo.png';
import sketchBench from '../assets/sketch_bench.png';
import johannesProfile from '../assets/cropped_edit.jpg';
import aiForBusiness from '../assets/ai_for_foretag.jpg';
import o1 from '../assets/blogs/o1.jpg';
import derailieure from '../assets/derailieure.jpg';
import canva from '../assets/collaborative_workspace_with_openai_canva.webp';
import gemini from '../assets/blogs/google-gemini.jpg';
import claudeAI from '../assets/blogs/claudeAI.png';

import cykelNatur from '../assets/customers/cykel-natur.png';
import golowLogo from '../assets/customers/golowLogo.png';
import mysItalia from '../assets/customers/mys_italia.png';
import husstainability from '../assets/customers/husstainability.png';
import lillaStaderiet from '../assets/customers/lilla_staderiet.png';
import o1Logo from '../assets/work_with/o1.png';
import o4 from '../assets/work_with/4o.png';
import chatgpt from '../assets/work_with/chatgpt.png';
import dalle from '../assets/work_with/dalle.png';
import gptForWork from '../assets/work_with/gpt_for_work.png';
import allAi from '../assets/blogs/all_ai.png';
import midjourneyGirl from '../assets/blogs/midjourney_girl.png';
import customGPT from '../assets/blogs/custom_gpt.jpg';
import aiAgent from '../assets/blogs/ai_agent.jpg';
import openAILogo from '../assets/blogs/openai.svg';
import o1_science from '../assets/blogs/o1_science.jpg';
import deepSeek from '../assets/blogs/deep_seek.png';
import deepSeekAppstore from '../assets/blogs/deep_seek_appstore.webp';
import deepSeekCost from '../assets/blogs/deep_seek_cost.png';
import oCost from '../assets/blogs/cost_oone.jpg';
import neuralNetwork from '../assets/blogs/neural_network.webp';
import deepResearch from '../assets/blogs/deep_research.webp';
import deepResearchLogo from '../assets/blogs/deep_research_logo.webp';
import brandLabHero from '../assets/brandlab/brand_lab_hero.png';
import brandLabOverview from '../assets/brandlab/overview.png';
import bikeParts from '../assets/bikeparts/pads_many.png';
import bikePartsLogo from '../assets/bikeparts/logo.png';

const images = {
    thingshappen,
    services01,
    services02,
    services03,
    services04,
    services05,
    services06,
    team01,
    testimonial1,
    testimonial2,  
    testimonial3,
    testimonial4,
    carpenter,
    hero,
    chatGPTmascot,
    robotReading,
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    hi,
    robotByDesk,
    logo,
    aiconsult,
    aiface,
    brandlab,
    artaiLogo,
    sketchBench,
    johannesProfile,
    aiForBusiness,
    derailieure,
    o1,
    canva,
    gemini,
    claudeAI,
    cykelNatur,
    golowLogo,
    mysItalia,
    husstainability,
    lillaStaderiet,
    o1Logo,
    o4,
    chatgpt,
    dalle,
    gptForWork,
    allAi,
    midjourneyGirl,
    customGPT,
    aiAgent,
    openAILogo,
    o1_science,
    deepSeek,
    deepSeekAppstore,
    oCost,
    deepSeekCost,
    neuralNetwork,
    deepResearch,
    deepResearchLogo,
    brandLabHero,
    brandLabOverview,
    bikeParts,
    bikePartsLogo
};
    
export default images;