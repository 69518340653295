import React, { Suspense, lazy, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { Consult } from './container';
import Layout from './components/Layout/Layout';
import { LanguageProvider } from './constants/localisations/language_context';

import { ENDPOINT_PRODUCTS_SV, ENDPOINT_NEWS_SV, ENDPOINT_CONSULT_SV, ENDPOINT_PRODUCTS_ENG, ENDPOINT_NEWS_ENG, ENDPOINT_CONSULT_ENG } from './constants/strings';

const ChatGPTCourse = lazy(() => import('./container/ChatGPTCourse/ChatGPTCourse'));
const VideoPreview = lazy(() => import('./container/ChatGPTCourse/VideoPreview/VideoPreview'));
const Course = lazy(() => import('./container/ChatGPTCourse/Course/Course'));
const Login = lazy(() => import('./container/Login/Login'));
const PaywallForm = lazy(() => import('./container/Paywall/PaywallForm'));
const Logout = lazy(() => import('./container/Login/Logout'));
const Products = lazy(() => import('./container/Products/Products'));
const Start = lazy(() => import('./container/Start/Start'));
const NewsFeed = lazy(() => import('./container/News/NewsFeed'));
const News = lazy(() => import('./container/News/News'));
const ThankYou = lazy(() => import('./container/Paywall/ThankYou'));
const ProductLinks = lazy(() => import('./container/ProductLinks/ProductLinks'));
const Register = lazy(() => import('./container/Paywall/Register'));
const ChatBot = lazy(() => import('./container/ChatBot/ChatBot'));
const BikeParts = lazy(() => import('./container/bikeparts/BikeParts'));
const BrandLab = lazy(() => import('./container/Products/Apps/brand_lab/BrandLab'));
// terms and condis
const SBPrivacyPolicy = lazy(() => import('./container/PrivacyPolicies/SB/SBPrivacyPolicy'));
const SBPTerms = lazy(() => import('./container/PrivacyPolicies/SB/SBPTerms'));
const CRPrivacyPolicy = lazy(() => import('./container/PrivacyPolicies/Color Rise/CRPrivacyPolicy'));
const CRPTerms = lazy(() => import('./container/PrivacyPolicies/Color Rise/CRPTerms'));
const SWYSPrivacyPolicy = lazy(() => import('./container/PrivacyPolicies/SWYS/SWYSPrivacyPolicy'));
const SWYSPTerms = lazy(() => import('./container/PrivacyPolicies/SWYS/SWYSPTerms'));
const BikeCalculator = lazy(() => import('./container/bikecalculator/BikeCalculator'));
const DaveWoodTerms = lazy(() => import('./container/PrivacyPolicies/Dave wood/DaveWoodTerms'));

const App = () => {

  useEffect(() => {
    const disableHorizontalScroll = () => {
      document.body.style.overflowX = 'hidden';
    };

    disableHorizontalScroll();

    return () => {
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  const CookieConsentWrapper = () => {

    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'accepted') {
      return null;
    }

    return (
      <CookieConsent
        location="bottom"
        buttonText="Acceptera"
        cookieName="consentCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: "#509D72", color: "#ffff", fontSize: "16px" }}
        onAccept={(acceptedByScrolling) => {
          if (!acceptedByScrolling) {
            localStorage.setItem('cookieConsent', 'accepted');
          }
        }}
        declineButtonText="Neka"
        declineButtonStyle={{ background: "#7B6F6F", color: "#ffffff", fontSize: "16px" }}
        enableDeclineButton
        onDecline={() => {
          localStorage.setItem('cookieConsent', 'declined');
        }}
      >
        Genom att klicka på "Acceptera" samtycker du till lagringen av cookies på din enhet.{" "}
      </CookieConsent>
    )
  }

  return (
    <Router>
      <LanguageProvider>
        <Layout children={
          <div className="container">
            <Menu />
            <Suspense fallback={<div>Laddar...</div>}>
              <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/chatgpt-kurs" element={<ChatGPTCourse />} />
                <Route path="/videopreview" element={<VideoPreview />} />
                <Route path="/kurs" element={<Course />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/tack" element={<ThankYou />} />
                <Route path="/kassa" element={<PaywallForm />} />
                <Route path="/registrering" element={<Register />} />
                <Route path="/bikeparts" element={<BikeParts />} />
                <Route path={ENDPOINT_NEWS_SV} element={<NewsFeed />} />
                <Route path={`${ENDPOINT_NEWS_SV}/:slug`} element={<News />} />
                <Route path={ENDPOINT_CONSULT_SV} element={<Consult />} />
                <Route path={ENDPOINT_PRODUCTS_SV} element={<Products />} />
                <Route path="/brandlab" element={<BrandLab />} />
                <Route path="/bikecalculator" element={<BikeCalculator />} />
                <Route path={ENDPOINT_NEWS_ENG} element={<NewsFeed />} />
                <Route path={`${ENDPOINT_NEWS_ENG}/:slug`} element={<News />} />
                <Route path={ENDPOINT_CONSULT_ENG} element={<Consult />} />
                <Route path={ENDPOINT_PRODUCTS_ENG} element={<Products />} />

                <Route path="/links" element={<ProductLinks />} />
                <Route path="/chatbot" element={<ChatBot />} />
                <Route path="/sb-privacypolicy" element={<SBPrivacyPolicy />} />
                <Route path="/sb-terms" element={<SBPTerms />} />
                <Route path="/cr-privacypolicy" element={<CRPrivacyPolicy />} />
                <Route path="/cr-terms" element={<CRPTerms />} />
                <Route path="/swys-privacypolicy" element={<SWYSPrivacyPolicy />} />
                <Route path="/swys-terms" element={<SWYSPTerms />} />
                <Route path="/dave-wood-terms" element={<DaveWoodTerms />} />
              </Routes>
            </Suspense>
            <CookieConsentWrapper />
          </div>
        } />
      </LanguageProvider>
    </Router>
  );
};

export default App;