import images from './images';
import { ENDPOINT_CHATGPT_COURSE_ENG, ENDPOINT_CONSULT_ENG, ENDPOINT_NEWS_ENG } from './strings';
const Menu = [
    {
        text: 'ChatGPT Kurs',
        link: '/chatgpt-kurs',
    },
    {
        text: 'AI-Konsult',
        link: '/aikonsult',
    },
    {
        text: 'AI-nyheter',
        link: '/ainyheter',
    },
    {
        text: 'Om Techlära',
        link: '/',
    },
    {
        text: 'Logga in',
        link: '/login',
        id: 'login',
    },
    {
        text: 'Språk',
        link: '/sv',
        id: 'language',
    },
];

const Team = [
    {
        name: 'Johannes Olsson',
        position: 'Konsult & föreläsare',
        info: 'Johannes har sedan ungdomen varit en techentusiast som byggt hemsidor, appar och alltid anammat den senaste tekniken. I 10 år arbetade han som iOS utvecklare men har nu specialiserat sig inom AI',
        photo: images.team01,
        linkedin: '#',
    },
];

const USP = [
    {
        header: 'Konkreta exempel',
        info: 'Allt material kommer från egen erfarenhet och är specialanpassat för dig som företagare eller för att klara av dina arbetsuppgifter.',
        photo: images.i6,
    },
    {
        header: 'Inga förkunskaper',
        info: 'Kurs anpassad för att inte kräva några förkunskaper. Efter kursen kommer du kunna använda ChatGPT som en avancerad användare',
        photo: images.i1,
    },
    {
        header: '2.5h över 25 delkurser',
        info: 'Förutom ChatGPT kommer du bland annat använda relaterade verktyg så som dataanalys och bildskapande med DALLE.',
        photo: images.i2,
    },
    {
        header: 'Välj tid och plats själv',
        info: 'Du kommer åt den inspelade kursen via vår plattform och gör kursen i din egna takt på tid som passar dig. Du har möjlighet att se respektive del flera gånger. ',
        photo: images.i3,
    },
    {
        header: 'Automatiserad arbetsdag',
        info: '95% av våra deltagare har möjlighet att automatisera 1-3H av sin arbetsdag med ChatGPT och AI. Vi visar hur. ',
        photo: images.i4,
    },
    {
        header: 'Kurs på Svenska',
        info: 'Kurs och allt material är på Svenska för att passa den svenska arbetsmarknaden.  ',
        photo: images.i5,
    },
];

const ServicesData = [
    {
        titleone: 'Kurs i',
        titletwo: 'ChatGPT',
        link: '/chatgpt-kurs',
        usps: '2.5h när du vill, 25 delkurser, Välj tid och plats själv',
        itemclass: 'blight',
        imgURL: images.services01,
    },
    {
        titleone: 'Zapier &',
        titletwo: 'Make automatisering',
        link: '/aikonsult',
        usps: 'Ta bort repetativa uppgifter, Sparar timmar',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },

    {
        titleone: 'OpenAI API',
        titletwo: 'Integration',
        link: '/aikonsult',
        usps: 'Bygg en chatbot, automatisera ditt företag',
        itemclass: 'bdark',
        imgURL: images.services04,
    },

    {
        titleone: 'Anlita en',
        titletwo: 'AI-konsult',
        link: '/aikonsult',
        usps: 'Vi hjälper dig hitta punkter att effektivisera, Bygger och optimerar AI-lösningar',
        itemclass: 'blight',
        imgURL: images.johannesProfile,
    },
    {
        titleone: 'Våra',
        titletwo: 'produkter',
        link: '/produkter',
        usps: 'Vi har byggt produkter i 10 år, Här är resultatet',
        itemclass: 'bgreen',
        imgURL: images.brandlab,
    },
]

const ProductsData = [
    {
        titleone: 'Chatbot',
        titletwo: 'för ditt företag',
        link: '/chatbot',
        usps: 'Vi hjälper er att bygga en Chatbot för ditt företag.',
        itemclass: 'blight',
        imgURL: images.artaiLogo,
    },
    {
        titleone: 'Växelörons',
        titletwo: 'AI',
        link: '/bikeparts',
        usps: 'Tillsammans med butiken Cykel & Natur har vi byggt en AI som hjälper dig hitta rätt växelöron till din cykel.',
        itemclass: 'bdark',
        imgURL: images.derailieure,
    },
    {
        titleone: 'Sketch',
        titletwo: 'Bench',
        link: 'https://apps.apple.com/us/app/stickers-filter-sketch-bench/id937042017',
        usps: 'Skapa unika filter, stickers och annat innehåll, för sociala medier',
        itemclass: 'blight',
        imgURL: images.sketchBench,
    },
    {
        titleone: 'Brand',
        titletwo: 'Lab',
        link: '/brandlab',
        usps: 'Skapa en story, video eller annat innehåll, för sociala medier',
        itemclass: 'blight',
        imgURL: images.brandlab,
    },

    {
        titleone: 'Bike ',
        titletwo: 'hotel planner',
        link: 'https://bikehotelplanner.com/',
        usps: 'Planera din cykelresa',
        itemclass: 'bdark',
        imgURL: "",
    },
    {
        titleone: 'The',
        titletwo: 'Incognita',
        link: 'https://theincognita.com/',
        usps: 'Network states list',
        itemclass: 'bdark',
        imgURL: "",
    },
    {
        titleone: 'Art',
        titletwo: 'AI',
        link: 'https://artai.se/',
        usps: 'AI genererad konst som posters',
        itemclass: 'blight',
        imgURL: images.artaiLogo,
    },
]
const ServicesExtendedData = [
    {
        header: 'AI-agenter',
        headerTagline: 'Vi hjälper dig att bygga en AI-agent',
        text: `AI-agenter är en ny typ av AI som kan utföra uppgifter som är mer komplexa än att bara svara på frågor. Ofta delar en agen upp informationen i olika delar och löser varje del separat. När alla delar är avklarade så kan agenten autonomt fortsätta att försöka lösa grundproblemet om den inte redan uppnått målet som specificierades i initiala prompten.`,
    },
    {
        header: 'Chatbot för din hemsida',
        headerTagline: 'Vi hjälper er att bygga en chatbot för ert företag',
        text: `
I en värld där kunder förväntar sig snabba och korrekta svar, kan en chatbot vara en hjälpsam lösning för att förbättra kundupplevelsen. Med hjälp av den senaste AI teknologin kan vi skapa en intelligent chatbot som lär sig svara på frågor om ditt företag och dina produkter – helt anpassad efter din verksamhet.

Genom att träna chatboten med relevant information om ert företag ser vi till att den kan hantera allt från vanliga frågor till mer specifika detaljer om era produkter och tjänster. Chatboten kan integreras direkt på din webbplats eller i dina kundkommunikationskanaler, vilket gör att dina kunder snabbt får svar, dygnet runt.`,
        link: '/chatbot',
        linkTitle: "Mer om hur ni bygger en chatbot för din hemsida"
    },
    {
        header: 'Automatisera din verksamhet',
        headerTagline: 'Vi automatiserar dina flöden',
        text: `I en värld där tid är pengar, erbjuder Techlära skräddarsydda automatiseringslösningar som förvandlar dina arbetsprocesser till så lite som ett knapptryck. Med hjälp av ledande verktyg som Zapier och Make, optimerar vi dina dagliga uppgifter och skapar automatiserade flöden som sparar både tid och pengar.
        
**Vi skapar och implementerar automatiserade lösningar:**
        
Vi analyserar dina nuvarande arbetsflöden och identifierar möjligheter för automatisering. Vårt team designar och implementerar anpassade flöden som integrerar dina applikationer och tjänster, så att data kan flyta fritt och effektivt utan manuellt ingripande.

Låt oss hjälpa dig att navigera i automatiseringens värld och transformera ditt sätt att arbeta. Kontakta oss idag för en konsultation och börja din resa mot en mer automatiserad och effektiv arbetsdag.`,
        link: '/ainyheter/effektivisera-ditt-f-retag-med-ai-en-n-dv-ndig-strategi-f-r-framtiden',
        linkTitle: "Mer om hur du automatiserar ditt företag"
    },
    {
        header: 'OpenAI API integration',
        headerTagline: 'Öppna upp möjligheterna med OpenAIs API',
        text: `Techlära är din partner för integrera OpenAI:s kraftfulla API:er, som förvandlar hur ditt företag interagerar med kunder och optimerar interna processer. Genom att tillämpa det senaste inom AI-teknologi, anpassar vi smarta, skalbara lösningar som tar din verksamhet till nästa nivå.

Vi utformar och implementerar anpassade AI-lösningar med OpenAI:s teknologi, vilket möjliggör automatisering av kundtjänst, innehållsgenerering och mycket mer.

Vår expertis inom AI och OpenAI:s API möjliggör innovation och skapar nya möjligheter för ditt företag att växa och utvecklas.

Låt oss hjälpa dig att effektivisera din verksamhet och skapa nya kundupplevelser med OpenAI:s API. Ta steget idag och transformera din affärsmodell med framtidens teknologi.`,
    },
    {
        header: 'Bygg en AI-app eller Chatbot',
        headerTagline: 'Vi har byggt produkter i 10 år.',
        text: `Johannes har byggt iOS-appar och webbappar i 10 år. Han kan snabbt hjälpa ert företag att bygga en prototyp, en AI-app eller en Chatbot. Kolla in produkterna han har skapat med länken nedan.`,
        link: '/produkter',
        linkTitle: "Mer om våra produkter"
    },
    {
        header: 'AI Expert Stockholm',
        headerTagline: 'Din AI konsult i storstaden',
        text: `Vill du träffas för ett fysiskt möte? Inga problem! Vi håller till i Stockholm och träffas gärna här eller om du inte har möjlighet att komma till Stockholm så kan du boka ett möte via vår Calendly. `,
    },
]

const Testimonials = [
    {
        name: 'Stephen Svanholm - ',
        position: 'Arstist manager på Svanholm Artists Management',
        testimonial: '"Johannes har skapat en fantastisk kurs här. Han leder dig igenom hur vi kan utnyttja ChatGPT i vårt dagliga arbete, och ger oss en rik förståelse av dess kraft. Inom ett par timmar hade jag skapat en egen Custom GPT. Starkt rekommenderat!"',
        photo: images.testimonial1,
    },
    {
        name: 'Linn Kristensen - ',
        position: 'Marketing Specialist på Kristensen Consulting',
        testimonial: '"Jag har lärt mig mycket nytt, och fick en bra översikt över vad som är möjligt samt inspiration till hur och vad man kan använda ChatGPT till. Det känns som att kursen passar perfekt till personer som har provat på och använt lite, men inte ser eller förstår den fulla potentialen än (precis som jag) och nybörjare eller de som aldrig har testat förut."',
        photo: images.testimonial2,
    },
];

const BikeParts =
{
    title: 'AI för dina cykeldelar',
    heroText: `På sidan kan du ta foto på ett växelöra eller bromsbelägg. Du hittar även smarta filtreringsfunktioner och snart kommer vi bygga en chatbot du kan prata med för att får bästa möjliga service att hitta dina cykeldelar.`,
    text: 'Vi har tagit fram en smart identifieringsfunktion som gör det enkelt och snabbt att hitta rätt cykeldelar. Just nu kan vi identifiera cirka 300 olika växelöron samt flera typer av bromsbelägg, och vi jobbar ständigt med att utöka vårt sortiment och förbättra vår AI-teknik. Anmäl dig till vårt nyhetsbrev för att hålla dig uppdaterad om kommande förbättringar och nya produkter!',
    cta: `Besök bikeparts.ai`,
    imageAlt: `hitta cykeldelar med AI`
};

const ChatBot =
    {
        title: 'Chatbot för ditt företag',
        heroText: 'Kunder förväntar sig ofta snabba och korrekta svar, vår automatisk kundtjänst kan därför vara en hjälpsam lösning för att förbättra kundupplevelsen. Med hjälp av den senaste AI teknologin kan vi skapa en intelligent chatbot som lär sig svara på frågor om ditt företag och dina produkter – helt anpassad efter din verksamhet. Pröva den på botten av sidan.',
        text: `
## Automatisk kundtjänst tränad för ditt företag

Genom att träna chatboten med relevant information om ditt företag ser vi till att den kan hantera allt från vanliga frågor till mer specifika detaljer om era produkter och tjänster. Chatboten kan integreras direkt på din webbplats eller i dina kundkommunikationskanaler, vilket gör att dina kunder snabbt får svar, dygnet runt.

Att komma igång är enkelt. Vi hjälper er att samla in och strukturera all nödvändig information, vare sig det är produktbeskrivningar, FAQ-sektioner eller supportdokument. Därefter skapar vi en chatbot som inte bara svarar på frågor utan gör det med en ton och stil som matchar ditt varumärke.

## Testa chatboten gratis

För att ni ska känna er trygga med lösningen erbjuder vi en kostnadsfri testperiod på en månad. Är ni inte nöjda behöver ni inte betala. Under den tiden får ni möjlighet att utvärdera hur väl chatboten presterar och hur den möter dina kunders behov. Efter testperioden kan ni fortsätta använda tjänsten för en månadsavgift på endast 1500 kr/mån.

Investera i framtiden för din kundservice idag och se hur en AI-driven chatbot kan lyfta din verksamhet. Kontakta oss för att börja din kostnadsfria månad – vi ser fram emot att hjälpa ditt företag till en bättre kundupplevelse!`,
contact: `Maila oss på [hej@techlara.se](mailto:hej@techlara.se) för att komma igång. eller läs mer på: [AI-konsult](/aikonsult)`,
chatBotSubHeader: `## Chatbot byggd för vår kund Cykel & Natur`,
cta: `Jag vill ha en chatbot`,
imageAlt: `chatbot for företag`
    };

const ConsultTestimonials = [
    {
        name: 'Erik Svedin - ',
        position: 'VD Cykel och Natur, Årsta',
        testimonial: '"Johannes och jag har haft ett nära samarbete där han hjälpt mig förbättra min butiks digitala närvaro. Han byggde snabbt en skylttillverkare för våra cyklar i butiken, har automatiserat vår uppladdning av produkter till vår webbshop och tillsammans har vi skapat AI verktyget bikeparts.ai"',
        photo: images.testimonial3,
    },
    {
        name: 'Peter Holmgrene - ',
        position: 'VD GoLow',
        testimonial: '"Johannes vägledde oss i vår process att utveckla ett nytt AI-verktyg. Han gav oss bra input om vilka verktyg och AI-lösningar som passade bra för vårt ändamål."',
        photo: images.testimonial4,
    },
];

const Faqs = [
    {
        question: 'Vilken är den senaste versionen av ChatGPT?',
        answer: 'ChatGPT o1 heter den senaste versionen av ChatGPT: Den är en resonerande modell som genom att resonera med sig själv kan komma fram till bättre svar.',
    },
    {
        question: 'ChatGPT på Svenska?',
        answer: 'ChatGPT är faktiskt riktigt bra på svenska. Även fast största delen av träningsdatan är på engelska så är ChatGPT bra på att översätta till svenska.',
    },
    {
        question: 'Är ChatGPT gratis?',
        answer: `Ja, ChatGPT finns i en gratis version. OpenAI erbjuder både en gratisversion och en betald version av ChatGPT. Gratisversionen ger tillgång till basfunktioner, men har vissa begränsningar, som t.ex. tillgång till äldre modeller och lägre prioritet vid hög belastning.

Den betalda versionen, kallad ChatGPT Plus, kostar $20 per månad och ger tillgång till den senaste GPT-4-modellen (o1) samt snabbare svarstider och högre tillgänglighet även under tider med hög trafik.`,
    },
    {
        question: 'Vad kostar ChatGPT Plus?',
        answer: 'PLUS versionen av ChatGPT där ChatGPT 4, ChatGPT4o och o1 ingår kostar 20$/månad eller 25$/månad om du har en enterpriselösning.',
    },
];

const ChatGPTCourseSEO = [
    {
        header: 'Vad är ChatGPT o1',
        headerTagline: 'OpenAIs senaste modell',
        text: `
OpenAI har lanserat o1, en ny AI-modell som är utformad för komplex resonering och förbättrad problemlösning inom vetenskap, programmering och matematik. Modellen är långsammare än tidigare versioner eftersom den spenderar mer tid på att bearbeta information noggrant, vilket resulterar i ökad noggrannhet. Särskilt inom kodning och vetenskapliga fält som fysik och kemi har o1 visat sig vara överlägsen.

Modellen integrerar också förbättrade säkerhetsfunktioner, vilket gör den mer ansvarsfull och säker att använda. o1 finns tillgänglig via ChatGPT och API , vilket möjliggör bred användning i forsknings- och utvecklingsprojekt.`,
    },
    {
        header: 'ChatGPT 5?',
        headerTagline: 'Vilken är den senaste versionen av ChatGPT?',
        text: `ChatGPT 4, lanserad i mars 2023, markerade ett stort framsteg jämfört med sin föregångare, ChatGPT 3.5, genom att introducera förmågan att behandla både text, bilder, csv-filer och röst. Med 1.76 biljoner parametrar, jämfört med GPT-3.5:s 175 miljarder, utökade GPT-4 också sin kontextfönsterstorlek till 128k tokens, vilket möjliggjorde djupare och mer sammansatta svar​​. GPT-4 förbättrade kodningskapaciteter, erbjuder mer precisa svar och kan hantera komplexa arbetsuppgifter med högre tillförlitlighet och minskad bias jämfört med tidigare versioner​.

Nu har OpenAI lanserat den efterlängtade modellen som många trodde skulle heta GPT-5, men istället introduceras den under namnet o1. Denna nya modellserie tar stora kliv framåt jämfört med tidigare GPT-versioner, särskilt inom områden som komplex problemlösning och vetenskaplig resonemang. De första två modellerna, o1-preview och o1-mini, är designade för att hantera avancerade uppgifter med en prestanda som närmar sig PhD-nivå, särskilt inom ämnen som fysik och biologi. Detta innebär att modellerna är idealiska för att lösa problem som kräver djupare tankearbete och flera steg, som kvantfysik och avancerad kodning.

Trots denna imponerande förmåga har o1-modellerna vissa begränsningar jämfört med ChatGPT4o, den senaste i GPT-serien. Till exempel stöder de inte avancerade funktioner som att surfa på webben, hantera filer eller använda bilder, vilket gör ChatGPT4o mer praktisk för bredare användningsområden, särskilt inom verktygsbaserade och multimodala sammanhang. Även om o1-preview är kraftfullare för akademiska och tekniska tillämpningar, erbjuder ChatGPT4o fortfarande mer mångsidighet för vanliga användarfall.`,
        link: '/ainyheter/vad-r-chatgpt4o-omni',
        linkTitle: "Mer om ChatGPT 4o"
    },
    {
        header: 'Gemini vs Chatgpt',
        headerTagline: 'Vilken är bäst?',
        text: `
Gemini framstår med sin användarvänliga prisstruktur och integration i Googles ekosystem. Den erbjuder en gratis version med omfattande funktioner, och dess Pro-version har visats sig än mer kraftfull än GPT 4 i vissa tester​​. Båda modellerna kan skapa kreativt innehåll och utföra kodnings- och beräkningsuppgifter effektivt, men deras ansatser och användarupplevelser varierar, vilket gör dem lämpliga för olika behov och preferenser​. Gemini kan som vi visar i våra videos prestera bättre när det kommer till SEO hantering.`,
        link: '/ainyheter/vad-r-en-llm',
        linkTitle: "Mer om LLMs"
    },
    {
        header: 'Claude vs Chatgpt',
        headerTagline: 'Vilken är bäst?',
        text: `
Claude har fördelen när det gäller kreativt skrivande och att producera naturliga texter. Det är särskilt bra på att skapa unika idéer och berättelser som känns mindre generiska jämfört med ChatGPT. Vid korrekturläsning och faktakontroll presterar Claude också bättre genom att tydligt presentera misstag och förslag till förbättringar, vilket gör det till en effektivare redigeringspartner för långa texter. Claude utmärker sig dessutom inom programmering, där dess "Artifacts"-funktion ger användare en realtidsvy över kodens resultat, vilket gör det enklare att förstå och justera kod direkt, särskilt för nybörjare. Dock har ChatGPT kommit ifatt med släppet av Canvas.`,
        link: '/ainyheter/azfGuDbrJg',
        linkTitle: "Mer om ChatGPT Canvas"
    },
    {
        header: 'ChatGPT plus',
        headerTagline: 'OpenAIs betalda version',
        text: `
ChatGPT Plus är en premiumversion av OpenAIs textgenereringsmodell, tillgänglig för $20 per månad. Den erbjuder utökade funktioner och fördelar för användare som behöver mer avancerade möjligheter. Med ChatGPT Plus får du tillgång till ChatGPT4o, en kraftfullare modell som kan leverera mer komplexa och detaljerade svar på dina frågor. Dessutom kan du dra nytta av funktioner som möjligheten att anpassa och finjustera modellen för dina specifika behov, samt att integrera den med andra system och plattformar.

ChatGPT Plus ger också en högre grad av support och tillgänglighet, inklusive snabbare svarstider och dedikerad hjälp för dina frågor och behov. För företag och organisationer som hanterar stora mängder textdata eller behöver effektiva kommunikationsverktyg med kunder eller användare, är ChatGPT Plus ett värdefullt verktyg som kan öka produktiviteten och effektiviteten.`
    },
    {
        header: 'Gratis ChatGPT',
        headerTagline: 'Finns det en gratis version av ChatGPT?',
        text: `
ChatGPT Gratis , som nu även involverar ChatGPT4o och andra features som tidigare kostade, är en tillgänglig och användarvänlig textgenereringsmodell som låter användare interagera med artificiell intelligens för att få svar på frågor, generera idéer eller ha konversationer. Även om den inte har samma omfattning som de kommersiella alternativen, levererar den ändå meningsfulla svar baserade på användarens input. Det är ett värdefullt verktyg för både personligt och professionellt bruk, och det är tillgängligt för alla utan kostnad.

Även om det finns vissa begränsningar, som till exempel begränsningar för antalet tecken per session eller antalet sessioner per dag, ger ChatGPT Basic användarna en första smak av möjligheterna med AI-driven textgenereringsteknologi.`
    },
]

export default { Menu, USP, Team, Testimonials, ConsultTestimonials, ServicesData, Faqs, ServicesExtendedData, ProductsData, ChatGPTCourseSEO, ChatBot, BikeParts };

