export const bikepartsSchemaEng = {
  keywords: ["AI for bike parts", "AI for derailleurs", "AI for bikes", "AI for bike brakes"],
  schemaMarkup: {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Premium Bike Parts",
    "description": "Discover premium bike parts to upgrade your cycling experience.",
    "brand": {
      "@type": "Brand",
      "name": "BikeParts Inc."
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "0",
      "itemCondition": "http://schema.org/NewCondition",
      "availability": "http://schema.org/InStock"
    }
  }
}

export const schemaMarkupStartEng = {
  keywords: [
  "AI consultant",
  "AI consulting",
  "AI education",
  "AI courses",
  "AI consulting",
  "Future technology",
  "Artificial intelligence",
  "Machine learning",
  "Technology consulting",
  "Digital innovations",
  "Technology education",
  "AI implementation"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Consulting and courses in AI, WEB3 and future technology",
    "description": "We offer AI courses and consulting for your company. Explore our services in artificial intelligence, WEB3 and future technology.",
    "url": "https://techlara.se/",
  "mainEntity": {
    "@type": "Organization",
    "name": "techlära",
    "url": "https://techlara.se/",
    "description": "Consulting services and education in AI, WEB3 and future technology",
    "sameAs": [
      "https://www.linkedin.com/company/techlara",
      "https://www.facebook.com/motionapps.ab",
      "https://www.instagram.com/techlara.se"
    ]
  },
  "offers": {
    "@type": "Offer",
    "itemOffered": [
      {
        "@type": "Service",
        "name": "AI courses",
        "description": "Education in artificial intelligence"
      },
      {
        "@type": "Service",
        "name": "AI consulting",
        "description": "Expert consulting in AI for businesses"
      }
    ]
    }
  }
}

export const consultSchemaEng = {
  keywords: [
    "AI consultant",
    "AI expert",
    "AI consulting",
    "AI implementation",
    "AI strategy",
    "Machine learning",
    "Data analysis",
    "Business automation",
    "Digitalization",
    "AI solutions",
    "Business development with AI",
    "AI education",
    "Predictive analysis",
    "Chatbots",
    "Natural language processing",
    "Computer Vision",
    "AI ethics",
    "AI for business",
    "Sustainable AI",
    "Custom AI services",
    "AI consulting Sweden"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "AI Consulting Services",
    "description": "Professional AI consulting services for businesses. We offer expertise in AI implementation, strategy development, and custom AI solutions.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://techlara.se"
    },
    "serviceType": "AI Consulting",
    "areaServed": "Sweden",
    "offers": {
      "@type": "Offer",
      "price": "Contact us for pricing",
      "priceCurrency": "SEK"
    }
  }
}

export const newsSchemaMarkupEng = {
  keywords: [
    "AI news",
    "Artificial intelligence",
    "Technology updates",
    "Digital trends",
    "Future technology",
    "Tech news",
    "AI development",
    "AI innovations",
    "English AI information",
    "Technological advances"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Collection of AI News",
    "description": "Read about AI news, site updates and social media updates",
    "url": "https://techlara.se/ainyheter",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": []  // This will be populated dynamically
    }
  }
}

export const chatbotSchemaEng = {
  keywords: [
    "Chatbot development",
    "AI chatbot company",
    "AI chatbot",
    "Customer service automation",
    "Business chatbot",
    "Digital customer service",
    "Chatbot integration",
    "Custom chatbot",
    "Intelligent chatbot",
    "Chatbot solutions",
    "Automated customer service"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Build your own chatbot",
    "description": "We help you build a Chatbot for your company.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://techlara.se"
    },
    "serviceType": "Build your own chatbot",
    "areaServed": "Sweden",
    "keywords": [
      "Chatbot development",
      "AI chatbot company",
      "AI chatbot",
      "Customer service automation",
      "Business chatbot",
      "Digital customer service",
      "Chatbot integration",
      "Custom chatbot",
      "Intelligent chatbot",
      "Chatbot solutions",
      "Automated customer service"
    ]
  }
}

export const productsSchemaMarkupEng = {
  keywords: [
    "AI products",
    "artificial intelligence",
    "machine learning",
    "techlara",
    "AI services"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "AI Products",
    "description": "We help you create AI products. Explore our services in artificial intelligence and machine learning.",
    "keywords": "AI products, artificial intelligence, machine learning, techlara, AI services",
    "url": "https://techlara.se/produkter"
  }
}

export const brandLabSchemaEng = {
  keywords: [
    "Brand Lab app",
    "Instagram stories",
    "Snapchat stories",
    "Video editing",
    "Animation app",
    "Social media tools",
    "Video animation",
    "Content creation",
    "Digital marketing",
    "Brand building",
    "Video design",
    "Animation tools",
    "Social media content",
    "Creative content",
    "Professional animation"
  ],
  schemaMarkup: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Brand Lab",
    "description": "Brand Lab - the ultimate app for creating professional Instagram and Snapchat stories, animations and videos.",
    "applicationCategory": "MultimediaApplication",
    "operatingSystem": "iOS",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "Easy media uploads",
      "Text customization",
      "Themes and filters",
      "Music library",
      "Instant previews",
      "Social media sharing"
    ]
  }
}