import React from 'react';
import { images } from '../../constants';
import WorkWithLogos from '../../components/IconScroll/WorkWithLogos';
import './Hero.css';
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG } from '../../constants/strings';

const Hero = () => {
  const { currentLanguage } = useLanguage();
  const handleButtonClick = () => {
    window.location.href = '/chatgpt-kurs'; // Redirect to '/chatgpt-kurs' on button click
  };

  const handleCallClick = () => {
    window.location.href = "tel:+46729449238";
  };

  const heroText = currentLanguage === LANG_ENG ? "We help your company navigate the AI jungle" : "Vi hjälper ditt företag att navigera i AI-djungeln";

  const p1 = currentLanguage === LANG_ENG ? "Tired of repetitive tasks? Techlära is here to make your life easier with the latest AI technology." : "Trött på repetativa uppgifter? Techlära är här för att underlätta din vardag med den senaste AI-tekniken.";
  const p2 = currentLanguage === LANG_ENG ? "In a time where technological advancements happen faster than ever, many companies face the challenge of identifying the right tools that not only meet their current needs but also foster long-term growth and innovation. This is where we can make a difference." : "I en tid där teknologiska framsteg sker snabbare än någonsin står många företag inför utmaningen att identifiera de rätta verktygen som inte bara uppfyller era aktuella behov utan också främjar långsiktig tillväxt och innovation. Det är här vi kan göra skillnad.";
  const p3 = currentLanguage === LANG_ENG ? "Check out our ChatGPT course! " : "Titta in vår kurs i ChatGPT! ";
  const buttonText = currentLanguage === LANG_ENG ? "Titta in kursen" : "Titta in kursen";
  const callButtonText = currentLanguage === LANG_ENG ? "Call me" : "Ring mig";
  
  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title"> {heroText} </h1>
           <p> {p1} </p>
          <p> {p2} </p>
          {currentLanguage !== LANG_ENG && 
          <p> {p3} </p>
          }
          {currentLanguage !== LANG_ENG && 
          <button className="btn-green btn-left" onClick={ handleButtonClick }> {buttonText} </button>
          }
          <button className="btn-green" onClick={ handleCallClick }> {callButtonText} </button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="AI Konsult logo" title="AI Konsult logo" loading="lazy" />
        </div>
      </div>
      <WorkWithLogos />
    </div>
    
  )
}

export default Hero