import { LANG_ENG, ENDPOINT_NEWS_ENG, ENDPOINT_NEWS_SV } from '../../constants/strings';


const InternalHyperLinks = [
  {
    "text": "LLM",
    "translation": "[LLM]",
    "id": "g0cSE4J3Jy"
  },
  {
    "text": "o1",
    "translation": "[o1]",
    "id": "y3H9x5Y2mN"
  },
  {
    "text": "ChatGPT4o",
    "translation": "[ChatGPT4o]",
    "id": "KUwPPKPdOG"
  },
  {
    "text": "Claude",
    "translation": "[Claude]",
    "id": "xKpLmNqRds"
  },
  {
    "text": "Gemini",
    "translation": "[Gemini]",
    "id": "rgbI9RMVdF"
  },
  {
    "text": "AI-agenter",
    "translation": "[AI-agenter]",
    "id": "AKfGaabrUb"
  },
  {
    "text": "Custom GPT",
    "translation": "[Custom GPT]",
    "id": "zMSm9aRGjk"
  },
  {
    "text": "Chatbot",
    "translation": "[Chatbot](/chatbot)",
    "id": "chatbot"
  }
  
]

  const addHyperLinks = (text, language) => {
    InternalHyperLinks.forEach(link => {
      if (text.includes(link.text)) {
        if (link.id === "chatbot") {
          text = text.replaceAll(link.text, link.translation);
        } else {
          text = text.replaceAll(link.text, link.translation + newsEndPoint(link, language));
        }
      }
    });
    return text;
  }

  const newsEndPoint = (link, language) => {
    return "(" + (language === LANG_ENG ? ENDPOINT_NEWS_ENG : ENDPOINT_NEWS_SV) + "/" + link.id + ")"
  }

  const addNewsHyperLinks = (text, currentNews, language) => {
    InternalHyperLinks.forEach(link => {
      if (link.id !== currentNews.id) {
        if (text.includes(link.text)) {
          text = text.replaceAll(link.text, link.translation + newsEndPoint(link, language));
        }
      }
    });
    return text;
  }

export { InternalHyperLinks, addHyperLinks, addNewsHyperLinks };  