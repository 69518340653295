import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RoundedSubHeader } from "../../components/Headings/Headings";
import ConsultHero from './ConsultHero';
import { Footer } from '../../container';
import data_eng from '../../constants/data_eng';
import data from '../../constants/data';
import Contact from '../Contact/Contact';
import WorkWithLogos from '../../components/IconScroll/WorkWithLogos';
import ConsultTestimonials from '../../container/Testimonials/ConsultTestimonials';
import ReactMarkdown from 'react-markdown';
import { addHyperLinks } from '../News/InternalHyperLinks';
import { consultSchemaSv } from '../../constants/localisations/schema_markups/schema_markups_sv';
import { consultSchemaEng } from '../../constants/localisations/schema_markups/schema_markups_eng';
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG, translatedInternalLink, CONSULT } from '../../constants/strings';


const Consult = () => {
  const { currentLanguage } = useLanguage();
  const schemaMarkup = currentLanguage === LANG_ENG ? consultSchemaEng : consultSchemaSv;
  const currentData = currentLanguage === LANG_ENG ? data_eng.ServicesExtendedData : data.ServicesExtendedData;

  useEffect(() => {
    document.documentElement.lang = currentLanguage;
    document.title = currentLanguage === LANG_ENG 
    ? "AI Consulting - We help your company implementing AI - techlära.se"
    : "AI konsult - Vi hjälper ert företag med AI - techlära.se";
  }, [currentLanguage]);

  const services = () => {
    return (
      <>
        {currentData.map(({ header, headerTagline, text, link, linkTitle }, index) => (
          <div className="service-container" key={index}>
            <RoundedSubHeader title={header} text={headerTagline} />
            <div >
              <ReactMarkdown>
                {addHyperLinks(text, currentLanguage)}
              </ReactMarkdown>
              {link && 
              <div>
              <br/>
                <a href={link}>{linkTitle}</a>
              </div>
              }
            </div>
            <br/><br/>
          </div>
        ))}
      </>
    );
  }

  const handleButtonClick = () => {
    window.open("https://calendly.com/techlara/30min", "_blank");
  };

  return (
    <div className="consult-container">
      <Helmet>
        <html lang={currentLanguage === LANG_ENG ? 'en' : 'sv'} />
        <link rel="canonical" href={translatedInternalLink(CONSULT, currentLanguage)} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        
        <meta 
          name="description" 
          content={currentLanguage === LANG_ENG
            ? "Professional AI consulting services for companies. We offer expertise in AI implementation, strategy development and customized AI solutions."
            : "Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering och skräddarsydda AI-lösningar."} 
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-Robots-Tag" content="index, follow" />
        <meta name="keywords" content={schemaMarkup.keywords.join(', ')} />
        <meta name="publisher" content={"Johannes Olsson - Techlära"} />
      </Helmet>
      <ConsultHero />
      <br/><br/>
      <ConsultTestimonials />
      <br/>
      <WorkWithLogos />
      <br/><br/><br/>
      <div id="kontakt">
        <Contact />
      </div>
      
      <br/><br/>
      {services()}
      <br />
      <button className="btn-green" onClick={handleButtonClick}>
        {currentLanguage === LANG_ENG ? "Book a meeting" : "Boka ett möte"}
      </button>
      
      <Footer />
    </div>
  );
};

export default Consult;