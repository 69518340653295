export const COURSE_ACTION = "Testa gratis";

// URL constants
export const URL_REGISTER = "/registrering";
export const URL_PAYWALL = "/kassa";

// Language constants
export const LANG_ENG = "eng";
export const LANG_SV = "sv";

// Internal link constants
export const CONSULT = "consult";
export const NEWS = "news";
export const PRODUCTS = "products";
export const CHATBOT = "chatbot";

// endpoints
export const ENDPOINT_CHATBOT = "/chatbot";

export const ENDPOINT_PRODUCTS_SV = "/produkter";
export const ENDPOINT_NEWS_SV = "/ainyheter";
export const ENDPOINT_CONSULT_SV = "/aikonsult";

export const ENDPOINT_PRODUCTS_ENG = "/products";
export const ENDPOINT_NEWS_ENG = "/ainews";
export const ENDPOINT_CONSULT_ENG = "/aiconsultant";

export const BASE_URL = "https://techlara.se";

export const translatedInternalLink = (type, currentLanguage) => {

  switch (type) {
    case CONSULT:
      return `${BASE_URL}${currentLanguage === LANG_ENG ? ENDPOINT_CONSULT_ENG : ENDPOINT_CONSULT_SV}`;
    case NEWS:
      return `${BASE_URL}${currentLanguage === LANG_ENG ? ENDPOINT_NEWS_ENG : ENDPOINT_NEWS_SV}`;
    case PRODUCTS:
      return `${BASE_URL}${currentLanguage === LANG_ENG ? ENDPOINT_PRODUCTS_ENG : ENDPOINT_PRODUCTS_SV}`;
    default:
      return "/";
  }
}