import React from 'react'
import './Team.css';
import { useLanguage } from '../../../constants/localisations/language_context';
import { LANG_ENG } from '../../../constants/strings';
import data_eng from '../../../constants/data_eng';
import data from '../../../constants/data';

const Team = () => {
  const { currentLanguage } = useLanguage();
  const teamData = currentLanguage === LANG_ENG ? data_eng.Team : data.Team;
  return (
    <div className="section-padding">
      <div className="row">
        {teamData.map(({ name, position, info, photo }, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-12">
            <div className="card-team">
              <div className="card-team-header"> <img src={photo} alt={name} />
                <p> 
                <span>{name}</span>
                  {position}
                </p>
              </div>
              <p>{info}</p>
              <br/>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team