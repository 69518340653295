import React from 'react';
import { RoundedSubHeader } from '../../../components/Headings/Headings';
import { BsDiscord, BsYoutube } from 'react-icons/bs'
import { useLanguage } from '../../../constants/localisations/language_context';
import { LANG_ENG } from '../../../constants/strings';
import './Community.css';

const Community = () => {

  const { currentLanguage } = useLanguage();
  const subHeader = currentLanguage === LANG_ENG ? "Learn more about AI in our forum or on our YouTube channel" : "Lär dig mer om AI i vårt forum eller på vår YouTube-kanal"
  const p1 = currentLanguage === LANG_ENG ? "Learn more about AI, get help and discuss with others in our forum or watch free content on YouTube." : "Lär dig mer om AI, få hjälp och diskutera med andra i vårt forum eller ta del av gratis content på YouTube."
  const p2 = currentLanguage === LANG_ENG ? "You can find our forum in the Discord app. You can download it or run it directly in your browser." : "Du hittar vårt forum i chatappen Discord. Som du kan ladda ner eller köra direkt i webläsaren."
  const discordText = currentLanguage === LANG_ENG ? "Techlära on Discord" : "Techlära på Discord"
  const youtubeText = currentLanguage === LANG_ENG ? "Techlära on YouTube" : "Techlära på YouTube"

  return (
    <div className="community">
      <br /><br /><br /><br />
      <RoundedSubHeader title="AI-community" text={subHeader} />

      <div className="content-container" style={{ display: 'flex' }}>
        <div className='text'>
          <p >
            {p1}
          </p>
          <p> 
            {p2}
          </p>
        </div>
        <div className="community-links-container" style={{ flex: 1 }}>
          <div className="community-link">
            <a href="https://discord.gg/GaGQeE23vd" className="icon-link" target="_blank" rel="noopener noreferrer">
              <BsDiscord className='discord-icon' color='#7289DA' size={70} alt="AI-community discord logo" />
            </a>
            <br />
            {discordText}
            
          </div>
          <div className="community-link">
            <a href="https://www.youtube.com/channel/UCXZthb2sGbrkS6aEX8EcEsg" className="icon-link" target="_blank" rel="noopener noreferrer">
              <BsYoutube className='youtube-icon' color='#FF0000' size={70} alt="AI-community youtube logo" title="AI-community youtube logo" />
            </a>
            <br />
            {youtubeText}
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
    </div>
  );
};

export default Community;
