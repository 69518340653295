import images from './images';
import { ENDPOINT_CONSULT_ENG, ENDPOINT_NEWS_ENG, ENDPOINT_PRODUCTS_ENG } from '../constants/strings';

const Menu = [
    {
        text: 'AI Consulting',
        link: `${ENDPOINT_CONSULT_ENG}`,
    },
    {
        text: 'AI News',
        link: `${ENDPOINT_NEWS_ENG}`,
    },
    {
        text: 'Chatbot',
        link: '/chatbot',
    },
    {
        text: 'Products',
        link: `${ENDPOINT_PRODUCTS_ENG}`,
    },
    {
        text: 'About Techlära',
        link: '/',
    },
    {
        text: 'Language',
        link: '/sv',
        id: 'language',
    },
];

const Team = [
    {
        name: 'Johannes Olsson',
        position: 'Consultant & Lecturer',
        info: 'Johannes has been a tech enthusiast since his youth, building websites, apps, and always embracing the latest technology. He worked as an iOS developer for 10 years but has now specialized in AI',
        photo: images.team01,
        linkedin: '#',
    },
];

const USP = [
    {
        header: 'Concrete Examples',
        info: 'All material comes from personal experience and is specially adapted for you as a business owner or to handle your work tasks.',
        photo: images.i6,
    },
    {
        header: 'No Prerequisites',
        info: 'Course designed to require no prior knowledge. After the course, you will be able to use ChatGPT as an advanced user',
        photo: images.i1,
    },
    {
        header: '2.5h Over 25 Sub-courses',
        info: 'Besides ChatGPT, you will use related tools such as data analysis and image creation with DALLE.',
        photo: images.i2,
    },
    {
        header: 'Choose Your Own Time and Place',
        info: 'You can access the recorded course through our platform and take the course at your own pace when it suits you. You have the opportunity to watch each part multiple times.',
        photo: images.i3,
    },
    {
        header: 'Automated Workday',
        info: '95% of our participants can automate 1-3 hours of their workday with ChatGPT and AI. We show you how.',
        photo: images.i4,
    },
    {
        header: 'Course in Swedish',
        info: 'Course and all materials are in Swedish to suit the Swedish job market.',
        photo: images.i5,
    },
];

const ServicesData = [
    {
        titleone: 'Zapier &',
        titletwo: 'Make Automation',
        link: `${ENDPOINT_CONSULT_ENG}`,
        usps: 'Remove repetitive tasks, Save hours',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },
    {
        titleone: 'OpenAI API',
        titletwo: 'Integration',
        link: `${ENDPOINT_CONSULT_ENG}`,
        usps: 'Build a chatbot, automate your business',
        itemclass: 'bdark',
        imgURL: images.services04,
    },
    {
        titleone: 'Hire an',
        titletwo: 'AI Consultant',
        link: `${ENDPOINT_CONSULT_ENG}`,
        usps: 'We help you find points to optimize, Build and optimize AI solutions',
        itemclass: 'blight',
        imgURL: images.johannesProfile,
    },
    {
        titleone: 'Our',
        titletwo: 'Products',
        link: `${ENDPOINT_PRODUCTS_ENG}`,
        usps: 'We have built products for 10 years, Here is the result',
        itemclass: 'bgreen',
        imgURL: images.brandlab,
    },
];

const BikeParts = {
    title: 'AI for your bike parts',
    heroText: `On the site, you can take a photo of a derailleur hanger or brake pad. You will also find smart filtering functions, and soon we will build a chatbot you can talk to for the best possible service in finding your bike parts.`,
    cta: `Visit bikeparts.ai` ,
    text: 'We have developed a smart identification function that makes it easy and quick to find the right bike parts. Currently, we can identify approximately 300 different derailleur hangers and several types of brake pads, and we are constantly working to expand our range and improve our AI technology. Sign up for our newsletter to stay updated on upcoming improvements and new products!',
    imageAlt: `find bike parts with AI`
};

const ChatBot = {
    title: 'Chatbot for your company',
    heroText: 'Customers often expect quick and accurate answers, which is why our automated customer service can be a helpful solution to improve the customer experience. Using the latest AI technology, we can create an intelligent chatbot that learns to answer questions about your company and products – completely customized for your business. Try it out at the bottom of the page.',
    text: `
## Automated customer service trained for your company

By training the chatbot with relevant information about your company, we ensure it can handle everything from common questions to more specific details about your products and services. The chatbot can be integrated directly on your website or in your customer communication channels, ensuring your customers get quick answers, 24/7.

Getting started is easy. We help you collect and structure all necessary information, whether it's product descriptions, FAQ sections, or support documents. Then we create a chatbot that not only answers questions but does so with a tone and style that matches your brand.

## Try the chatbot for free

To ensure you feel confident with the solution, we offer a free one-month trial period. If you're not satisfied, you don't need to pay. During this time, you'll have the opportunity to evaluate how well the chatbot performs and how it meets your customers' needs. After the trial period, you can continue using the service for only 1500 SEK/month.

Invest in the future of your customer service today and see how an AI-driven chatbot can elevate your business. Contact us to start your free month – we look forward to helping your company achieve a better customer experience!`,
    contact: `Email us at [hej@techlara.se](mailto:hej@techlara.se) to get started. or read more at: [AI Consultant](/aiconsultant)`,
    chatBotSubHeader: `## Chatbot built for our client Cykel & Natur`,
    cta: `I want a chatbot`,
    imageAlt: `chatbot for companies`
};

const ProductsData = [
    {
        titleone: 'Chatbot',
        titletwo: 'for your company',
        link: '/chatbot',
        usps: 'We help you build a chatbot for your company.',
        itemclass: 'blight',
        imgURL: images.robotByDesk,
    },
    {
        titleone: 'Derailleur',
        titletwo: 'AI',
        link: '/bikeparts',
        usps: 'Together with Cykel & Natur store, we have built an AI that helps you find the right derailleur for your bike.',
        itemclass: 'bdark',
        imgURL: images.derailieure
    },
    {
        titleone: 'Sketch',
        titletwo: 'Bench',
        link: 'https://apps.apple.com/us/app/stickers-filter-sketch-bench/id937042017',
        usps: 'Create unique filters, stickers and other content for social media',
        itemclass: 'blight',
        imgURL: images.sketchBench
    },
    {
        titleone: 'Brand',
        titletwo: 'Lab',
        link: '/brandlab',
        usps: 'Create a story, video or other content for social media',
        itemclass: 'blight',
        imgURL: images.brandlab
    },
    {
        titleone: 'Bike',
        titletwo: 'Hotel Planner',
        link: 'https://bikehotelplanner.com/',
        usps: 'Plan your bike trip',
        itemclass: 'bdark',
        imgURL: ''
    },
    {
        titleone: 'The',
        titletwo: 'Incognita',
        link: 'https://theincognita.com/',
        usps: 'Network states list',
        itemclass: 'bdark',
        imgURL: ''
    },
    {
        titleone: 'Art',
        titletwo: 'AI',
        link: 'https://artai.se/',
        usps: 'AI generated art as posters',
        itemclass: 'blight',
        imgURL: images.artaiLogo
    }
];

const ServicesExtendedData = [
    {
        header: 'AI Agents',
        headerTagline: 'We help you build an AI agent',
        text: `AI agents are a new type of AI that can perform tasks that are more complex than just answering questions. Often, an agent splits the information into different parts and solves each part separately. When all parts are resolved, the agent can autonomously continue to try to solve the main problem if it has not already achieved the goal specified in the initial prompt.`,
    },
    {
        header: 'Chatbot for Your Website',
        headerTagline: 'We help you build a chatbot for your company',
        text: `In a world where customers expect quick and accurate answers, a chatbot can be a helpful solution to improve the customer experience. Using the latest AI technology, we can create an intelligent chatbot that learns to answer questions about your company and products – completely customized for your business.

By training the chatbot with relevant information about your company, we ensure it can handle everything from common questions to more specific details about your products and services. The chatbot can be integrated directly on your website or in your customer communication channels, ensuring your customers get quick answers, 24/7.`,
        link: '/chatbot',
        linkTitle: 'More about how to build a chatbot for your website'
    },
    {
        header: 'Automate Your Business',
        headerTagline: 'We automate your workflows',
        text: `In a world where time is money, Techlära offers customized automation solutions that transform your work processes into as little as a button press. Using leading tools like Zapier and Make, we optimize your daily tasks and create automated workflows that save both time and money.
        
**We create and implement automated solutions:**
        
We analyze your current workflows and identify opportunities for automation. Our team designs and implements customized flows that integrate your applications and services, allowing data to flow freely and efficiently without manual intervention.

Let us help you navigate the world of automation and transform your way of working. Contact us today for a consultation and start your journey towards a more automated and efficient workday.`,
        link: `${ENDPOINT_NEWS_ENG}/9EO4wMKg53`,
        linkTitle: 'More about how to automate your business'
    },
    {
        header: 'OpenAI API Integration',
        headerTagline: "Open up the possibilities with OpenAI's API",
        text: `Techlära is your partner for integrating OpenAI's powerful APIs, transforming how your business interacts with customers and optimizes internal processes. By applying the latest in AI technology, we customize smart, scalable solutions that take your business to the next level.

We design and implement customized AI solutions with OpenAI's technology, enabling automation of customer service, content generation, and much more.

Our expertise in AI and OpenAI's API enables innovation and creates new opportunities for your business to grow and develop.

Let us help you streamline your operations and create new customer experiences with OpenAI's API. Take the step today and transform your business model with future technology.`,
    },
    {
        header: 'Build an AI App or Chatbot',
        headerTagline: 'We have built products for 10 years.',
        text: `Johannes has built iOS apps and web apps for 10 years. He can quickly help your company build a prototype, an AI app, or a Chatbot. Check out the products he has created with the link below.`,
        link: `${ENDPOINT_PRODUCTS_ENG}`,
        linkTitle: 'More about our products'
    },
    {
        header: 'AI Expert Stockholm',
        headerTagline: 'Your AI consultant in the big city',
        text: `Want to meet for a physical meeting? No problem! We are based in Stockholm and are happy to meet here, or if you cannot come to Stockholm, you can book a meeting via our Calendly.`,
    },
];

const Testimonials = [
    {
        name: 'Stephen Svanholm - ',
        position: 'Artist Manager at Svanholm Artists Management',
        testimonial: "Johannes has created a fantastic course here. He guides you through how we can utilize ChatGPT in our daily work, and gives us a rich understanding of its power. Within a couple of hours, I had created my own Custom GPT. Strongly recommended!",
        photo: images.testimonial1
    },
    {
        name: 'Linn Kristensen - ',
        position: 'Marketing Specialist at Kristensen Consulting',
        testimonial: "I have learned a lot new things, and got a good overview of what is possible and inspiration for how and what you can use ChatGPT for. It feels like the course is perfect for people who have tried and used a little, but don't see or understand the full potential yet (just like me) and beginners or those who have never tried before.",
        photo: images.testimonial2
    }
];

const ConsultTestimonials = [
    {
        name: 'Erik Svedin - ',
        position: 'CEO Cykel och Natur, Årsta',
        testimonial: "Johannes and I have had a close collaboration where he helped me improve my store's digital presence. He quickly built a sign maker for our bikes in the store, has automated our product upload to our webshop, and together we have created the AI tool bikeparts.ai",
        photo: images.testimonial3
    },
    {
        name: 'Peter Holmgrene - ',
        position: 'CEO GoLow',
        testimonial: "Johannes guided us in our process of developing a new AI tool. He gave us good input about which tools and AI solutions were suitable for our purpose.",
        photo: images.testimonial4
    }
];

const Faqs = [
    {
        question: 'What is the latest version of ChatGPT?',
        answer: 'ChatGPT o1 is the latest version of ChatGPT: It is a reasoning model that can arrive at better answers by reasoning with itself.',
    },
    {
        question: 'ChatGPT in Swedish?',
        answer: 'ChatGPT is actually really good at Swedish. Even though most of the training data is in English, ChatGPT is good at translating to Swedish.',
    },
    {
        question: 'Is ChatGPT free?',
        answer: `Yes, ChatGPT has a free version. OpenAI offers both a free version and a paid version of ChatGPT. The free version provides access to basic functions but has some limitations, such as access to older models and lower priority during high load.

The paid version, called ChatGPT Plus, costs $20 per month and provides access to the latest GPT-4 model (o1) as well as faster response times and higher availability even during times of high traffic.`,
    },
    {
        question: 'How much does ChatGPT Plus cost?',
        answer: 'The PLUS version of ChatGPT where ChatGPT 4, ChatGPT4o and o1 are included costs $20/month or $25/month if you have an enterprise solution.',
    },
];

export const BrandLab = {
  title: "Create stunning animations & videos with ease",
  heroText: "Turn your ideas into reality with Brand Lab – the ultimate app for creating professional Instagram and Snapchat stories, animations and videos.",
  imageAlt: "Brand Lab App Interface",
  cta: "Download Now",
  appStoreLink: "https://apps.apple.com/us/app/brand-lab-story-video-maker/id1375539591",
  
  text: `With Brand Lab, you don't need design experience or a big budget to craft eye-catching content that promotes your brand, lifestyle, or personal logo. Whether you're a business owner, influencer, or just looking to elevate your social media game, Brand Lab has everything you need to stand out online.

Why choose Brand Lab?
- Effortless Creativity: No design skills? No problem. Our app is intuitive and user-friendly.
- Save Money: Forget expensive animators. Brand Lab makes professional-quality animations accessible to everyone.
- Boost your visibility: Success is about getting noticed. Let your videos shine on Instagram, Snapchat, and beyond.`,

  features: `## Features that bring your vision to life

- Easy Media Uploads: Add 1-6 images and include intro/outro logos for a polished look.
- Endless Text Customization: Personalize fonts, colors, shading, and more to match your brand.
- Themes & Filters: Choose from a vast library of themes and filters to elevate your animations.
- Add Music: Select from our curated music library or upload your own tracks to set the tone.
- Instant Previews: Watch your ideas transform into stunning animations in seconds.
- Social Media Sharing: Seamlessly share your videos with friends, customers, and followers.`,

  downloadCta: `## Unleash your inner animator today!

Stop waiting and start creating. With Brand Lab, promoting your brand has never been easier or more fun. Download the app now and give your Instagram and Snapchat stories the WOW factor they deserve.`,

  demoText: "## See It in Action"
};

export default { Menu, USP, Team, Testimonials, ConsultTestimonials, ServicesData, Faqs, ServicesExtendedData, ProductsData, ChatBot, BikeParts, BrandLab };

