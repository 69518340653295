import React from 'react';
import ReactMarkdown from 'react-markdown';
import { images } from '../../constants';
import { Customers } from '../../components';
import './ConsutlHero.css';
import { RoundedSubHeader } from '../../components/Headings/Headings';
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG } from '../../constants/strings';

const ConsultHero = () => {
  const { currentLanguage } = useLanguage();
  const handleButtonClick = () => {
    window.open("https://calendly.com/techlara/30min", "_blank");
  };

  const handleCallClick = () => {
    window.location.href = "tel:+46729449238";
  };

  const title = currentLanguage === LANG_ENG ? "AI-consulting for your company" : "AI-konsult för ditt företag";

  const buttonText = currentLanguage === LANG_ENG ? "Book a meeting" : "Boka ett möte";
  const callButtonText = currentLanguage === LANG_ENG ? "Call me" : "Ring mig";
  const subHeader = currentLanguage === LANG_ENG ? "What we can help your company with" : "Vad vi kan hjälpa ert företag med";

  const subHeaderText = currentLanguage === LANG_ENG ? "Effective AI-consulting" : "Effektiv AI-konsultering";

  const p1 = currentLanguage === LANG_ENG ? "Tired of repetitive tasks? Techlära is here to make your life easier with the latest AI technology." : "Trött på repetativa uppgifter? Techlära är här för att underlätta din vardag med den senaste tekniken inom artificiell intelligens.";

  const bulletPoints = currentLanguage === LANG_ENG ? [
    "**Expertise:** 12+ years of experience in app development and deep knowledge of AI.",
    "**Flexibility:** Solutions tailored to your needs and budget.",
    "**Quality:** Focus on delivering projects on time and with the highest quality.",
    "**Cost:** With a small team and the latest AI technology, we can keep costs down."
  ] : [
    "**Expertis:** 12+ års erfarenhet av apputveckling och bred kunskap om AI.",
    "**Flexibilitet:** Lösningar anpassade till dina behov och din budget.",
    "**Kvalitet:** Fokus på att leverera projekt i tid och med högsta kvalitet.",
    "**Pris:** Ett litet team och den senaste tekniken inom AI håller nere kostnaderna."
  ]

  const p2 = currentLanguage === LANG_ENG ? "We help your company build AI apps, automations, chatbots and other solutions." : "Vi hjälper ditt företag att bygga AI-appar, automatiseringar, chatbottar och andra lösningar.";

  const p3 = currentLanguage === LANG_ENG ? "Our goal is to change your business with smart AI-choices. Let's together make sure your business is freed from repetitive tasks so you can focus on what you're good at!" : "Vårt mål är att förändra er verksamhet med smarta AI-val. Låt oss tillsammans se till att ditt företag blir av med repetativa uppgifter så ni kan fokusera på det ni är bra på!";

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">{title}</h1>
          <p>{p1}</p>
          <br/>
          <div className="bullet-points">
            {bulletPoints.map((bulletPoint, index) => (
              <div key={index} className="bullet-point mb-3">
                <span className="dot"></span>
                <ReactMarkdown components={{
                  p: ({children}) => <span>{children}</span>
                }}>{bulletPoint}</ReactMarkdown>
              </div>
            ))}
          </div>
          <button className="btn-green btn-left" onClick={handleButtonClick}> {buttonText} </button>
          <button className="btn-green" onClick={handleCallClick}> {callButtonText} </button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
        <img 
            className="img-fluid" 
            src={images.johannesProfile} 
            alt="AI expert Johannes Olsson" 
            title="AI expert Johannes Olsson"
            loading="lazy"
          />
        </div>
      </div>
      
      <div className="customers-container">
        <Customers />
      </div>
      
      <div className="intro-text">
        <RoundedSubHeader title={subHeader} text={subHeaderText} />
        <p>{p2}</p>
        <p>{p3}</p>
      </div>
    </div>
  )
}

export default ConsultHero;