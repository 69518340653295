import React from 'react'
import { RoundedSubHeader } from '../../components/Headings/Headings';
import './Contact.css';
import emailjs from 'emailjs-com';
import { useEffect, useState } from "react";
import { useLanguage } from '../../constants/localisations/language_context';
import { LANG_ENG } from '../../constants/strings';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { currentLanguage } = useLanguage();

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY), []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const templateParams = {
      to_name: "Johannes",
      from_name: name,
      from_email: email,
      message: message
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams)
      .then((response) => {
        alert('Email successfully sent!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send the email. Please try again.' + err.message);
      });
  };

  return (
    <div className="section-padding">
      <RoundedSubHeader
        title={
          currentLanguage === LANG_ENG ? "Hire an AI-expert" : "Anlita en AI-expert"
        } text={
          currentLanguage === LANG_ENG ? "We help you with AI" : "Vi hjälper er med AI"
        } />
      
      <div className="contact" id="quote">

        {currentLanguage === LANG_ENG ? "Would you like to get rid of repetitive tasks, train your own chatbot, build an AI-app/website, or book a lecture?" : "Vill du bli av med repetativa uppgifter, träna en egen chattbot, bygga en AI-app/hemsida, eller boka en föreläsning?"}
        <br/>
        <br/>
        {currentLanguage === LANG_ENG ? "You can book a meeting with our AI-expert Johannes via the link below:" : "Du kan boka ett möte med vår AI-expert Johannes via calendlylänken nedan:"}
        <br/>
        <br/> 
        <a className="big-link" href="https://calendly.com/techlara/30min" target="_blank" rel="noopener noreferrer">
          {currentLanguage === LANG_ENG ? "Book a free meeting" : "Boka kostnadsfritt möte"}
        </a>
        <br/>
        <br/> 

        {currentLanguage === LANG_ENG ? "Or you fill in your information below and we will contact you." : "Eller så fyller du i dina uppgifter nedan så kontaktar vi dig."}
        <div className="row">
          <div className="col-md-6 col-12">
            <form>
              <div className="form-group">
                <label>{currentLanguage === LANG_ENG ? "Name*" : "Namn*"}</label>
                <input className="form-control" type="text" placeholder={currentLanguage === LANG_ENG ? "Name" : "Namn"} onChange={handleNameChange}/>
              </div>
              <div className="form-group">
                <label>Email*</label>
                <input className="form-control" type="email" placeholder="Email" onChange={handleEmailChange}/>
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">{currentLanguage === LANG_ENG ? "Message" : "Meddelande"}</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleMessageChange}></textarea>
              </div>
              <button type="submit" className="btn-green w-100" onClick={handleSubmit}>
                {currentLanguage === LANG_ENG ? "Send" : "Skicka"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact